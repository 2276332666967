<template>
  <div>
    <div
      v-if="loading"
      class="flex w-screen h-screen items-center justify-center mt-10"
    >
      <spinner class="w-[200px] h-[200px]" color="red"></spinner>
    </div>

    <div v-else>
      <div
        v-if="brokenLink"
        class="flex flex-col justify-center items-center w-screen h-screen"
      >
        <img
          class="mx-auto"
          src="@/assets/images/icons/broken_link.svg"
          alt="loading icon"
          width="168"
          height="170"
        />
        <p class="text-center fw-bold">
          Check your email for a valid link or click
          <router-link
            class="font-bold text-[blue]"
            :to="{ name: 'Admin Login' }"
            >Login</router-link
          >
        </p>
      </div>
      <guest-layout v-else>
        <div class="flex items-center justify-center flex-col font-mulish">
          <h3 class="text-[#484848] text-base lg:text-2xl mt-7">
            Password Reset
          </h3>
          <h2 class="font-bold text-[#0090FF]">{{ email }}</h2>
          <span class="text-[12px] mt-1"
            >You need to secure your account with a password</span
          >

          <form
            @submit.prevent="proceed"
            class="bg-[#F9F9F9] p-5 mt-5 w-full lg:w-[698px] rounded form"
          >
            <div>
              <label for="password">Password<span class="req">*</span> </label>
              <ViewHidePassword
                v-model="form.password"
                @input="checkPasswordStrength"
              />
            </div>

            <div>
              <label for="passwordConfrim">
                Confirm Password<span class="req">*</span>
              </label>

              <ViewHidePassword v-model="form.confirmPassword" />
              <small class="req" v-if="form.confirmPassword && !passwordMatch"
                >Your password does not match the confirm password</small
              >
            </div>

            <div>
              <p
                class="textCss"
                style="color: #0090ff; font-size: 12px; text-align: left"
              >
                Password must contain a minimum of 8 characters, at least one
                uppercase, one lowercase, a Number and one Special Character
              </p>
              <ul id="myList">
                <li :class="`${lengthOk ? 'uljava' : ''}`">
                  Minimun 8 characters
                </li>
                <li :class="`${upperCheck ? 'uljava' : ''}`">UPPERCASE</li>
                <li :class="`${lowerCheck ? 'uljava' : ''}`">lowercase</li>
                <li :class="`${numCheck ? 'uljava' : ''}`">Number</li>
                <li :class="`${charCheck ? 'uljava' : ''}`">
                  Special character
                  <span>!@#$%^&*()_+[\]{};':"\\|,./?</span>
                </li>
              </ul>
            </div>

            <div>
              <button :disabled="proceeding || !formReady" type="submit">
                <span>Complete setup</span>
                <spinner v-if="proceeding"></spinner>
              </button>
            </div>

            <div class="text-[#484848] text-xs lg:text-base italic">
              After you complete your setup above, you will be redirected to a
              page, so you can login with your new password
            </div>
          </form>
        </div>
      </guest-layout>
    </div>
  </div>
</template>

<script>
import ViewHidePassword from "@/components/form/ViewHidePassword.vue";
import GuestLayout from "@/layouts/AdminOnboardingLayout.vue";
export default {
  name: "AdminCreatePassword",

  components: {
    GuestLayout,
    ViewHidePassword,
  },

  data() {
    return {
      brokenLink: true,
      proceeding: false,
      lengthOk: false,
      upperCheck: false,
      lowerCheck: false,
      numCheck: false,
      loading: false,
      charCheck: false,
      form: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    email() {
      return this.$store.getters["admin/onboardData"].email;
    },

    formReady() {
      return (
        this.form.password &&
        this.upperCheck &&
        this.lowerCheck &&
        this.numCheck &&
        this.lengthOk &&
        this.passwordMatch
      );
    },
    passwordMatch() {
      return this.form.password === this.form.confirmPassword;
    },
  },
  async beforeMount() {
    await this.verifyOnboardingToken();
  },
  methods: {
    async verifyOnboardingToken() {
      try {
        this.loading = true;
        const payload = this.$route.query;
        const res = await this.$http.post("/users/verify-token", payload, {
          headers: { noauth: true },
        });

        if (!res) {
          this.loading = false;
          this.brokenLink = true;
          return;
        }

        const { data } = res;

        this.$store.commit("admin/saveData", {
          key: "onboardData",
          data: data.data,
        });

        if (!data.data.isActive) {
          this.brokenLink = true;
        }

        this.loading = false;
        this.brokenLink = false;
      } catch (error) {
        console.log({ error });
      }
    },
    checkPasswordStrength() {
      this.upperCheck = /[A-Z]/.test(this.form.password);
      this.lowerCheck = /[a-z]/.test(this.form.password);
      this.numCheck = /\d/.test(this.form.password);
      this.charCheck = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]/.test(
        this.form.password
      );
      this.lengthOk = this.form.password.length >= 8;
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }
      try {
        this.proceeding = true;

        const payload = {
          userId: this.$store.getters["admin/onboardData"]._id,
          activationCode:
            this.$store.getters["admin/onboardData"].activationCode,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,
        };

        const res = await this.$http.post("/auth/a/reset-password", payload, {
          headers: { noauth: true },
        });

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.form.password = null;
        this.form.confirmPassword = null;

        this.$router.push({ name: "Admin Login" });
      } catch (error) {
        console.log({ error });
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
.req {
  @apply text-red-400;
}
input {
  @apply uppercase;
  height: 40px;
}
.form > div:not(:first-child) {
  @apply mt-4;
}
.form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
ul {
  color: #f00;
  font-size: 12px;
  text-align: left;
  flex-direction: column;
  margin-left: 24px;
  list-style-image: url("../../assets/images/icons/cancelcriteria.svg");
}
.uljava {
  color: #0090ff;
  font-size: 12px;
  text-align: left;
  flex-direction: column;
  list-style-image: url("../../assets/images/icons/criteriacheck.svg");
}

.textCss {
  color: #484848;
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.3%;
  margin: 8px;
}
</style>
